import NavigableModal, {
	MODAL_TYPES,
} from '@components/NavigableModal/NavigableModal';
import { useIsVenue } from '@hooks/use-is-venue';
import { H3 } from '@xo-union/tk-ui-typography';
import React, { type FC } from 'react';
import Styles from './EstimatedPricingDetailsModal.scss';

interface EstimatedPricingDetailsModalProps {
	isModalOpen: boolean;
	closeCallback: () => void;
}

const hash = 'estimated-pricing-details-modal';

export const EstimatedPricingDetailsModal: FC<
	EstimatedPricingDetailsModalProps
> = ({ isModalOpen, closeCallback }) => {
	const isVenue = useIsVenue();
	if (!isModalOpen) return null;

	const identifier = isVenue ? 'venue' : 'vendor';

	return (
		<NavigableModal
			hash={hash}
			isModalOpen={isModalOpen}
			closeCallback={closeCallback}
			type={MODAL_TYPES.NORMAL}
		>
			<H3>How does The Knot determine pricing for {identifier}s?</H3>
			<p>
				When a {identifier} provides their own pricing, we display this figure
				directly to ensure transparency.
			</p>
			<p>
				If the {identifier} hasn't provided a price, we use an estimation model.
				This model calculates the cost based on several key factors:
			</p>
			<ul className={Styles.keyFactorsList}>
				<li>
					<strong>Market Data:</strong> We analyze the pricing trends within the
					market to gauge standard costs for similar {identifier} types.
				</li>
				<li>
					<strong>Prices Submitted by Couples:</strong> Historical prices paid
					by couples offer real-world data that helps refine our estimates.
				</li>
				<li>
					<strong>{isVenue ? 'Venue' : 'Vendor'} Location:</strong> Pricing can
					vary significantly by location, influencing our estimates to reflect
					geographical pricing differences.
				</li>
				<li>
					<strong>Seasonality:</strong> Costs can fluctuate based on the time of
					year, affecting {identifier} prices during peak and off-peak seasons.
				</li>
			</ul>
		</NavigableModal>
	);
};
