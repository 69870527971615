import { Cta } from '@components/pricing/pricing-details/Cta';
import { DisclaimerText } from '@components/pricing/pricing-details/DisclaimerText';
import { Faq } from '@components/pricing/pricing-details/Faq';
import { EstimatedUsuallySpend } from '@components/shared-pricing/EstimatedUsuallySpend';
import { useStorefrontEmptyStates } from '@feature-flags/hooks/Storefront/useStorefrontEmptyStates';
import { Editorial } from '@xo-union/tk-ui-typography';
import { Body1 } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai';
import React from 'react';
import { PricingCta } from '../../../components/detailed-pricing/PricingCta';
import { useStorefrontEstimatedPricingExperiment } from '../../../hooks/use-storefront-estimated-pricing-experiment';
import {
	formattedPricingDetailsAtom,
	hasPricingDetailsAtom,
	venueEstimateUsuallySpendAtom,
} from '../../../jotai/storefront/pricing';
import { EmptyStateSearchLink } from '../../../pages/Storefront/components/EmptyStateSearchLink/EmptyStateSearchLink';
import Styles from './PricingDetails.scss';

export const PricingDetails = () => {
	const pricingDetails = useAtomValue(formattedPricingDetailsAtom);
	const hasPricingDetails = useAtomValue(hasPricingDetailsAtom);
	const isEmptyStates = useStorefrontEmptyStates();
	const estimatedPrice = useAtomValue(venueEstimateUsuallySpendAtom);
	const isEstimatedPricing = useStorefrontEstimatedPricingExperiment();
	const shouldUseEstimatedPricing = Boolean(
		isEstimatedPricing && estimatedPrice,
	);

	if (isEmptyStates && !hasPricingDetails && !estimatedPrice) {
		return (
			<EmptyStateSearchLink
				header="No pricing details yet"
				text="We’re here to help! Find other vendors that suit your budget."
				linkText="Continue browsing vendors"
				storefrontSection="Venue Pricing FAQs"
				inline={false}
			/>
		);
	}

	if (!hasPricingDetails && shouldUseEstimatedPricing) {
		return (
			<>
				<EstimatedUsuallySpend isVenue />
				<div className={Styles.noPricingDetailsWrapper}>
					<div className={Styles.noPricingDetails}>
						<Body1 bold>Looking for more pricing details?</Body1>
						<Body1>Reach out to get a customized quote from this venue.</Body1>
					</div>
					<PricingCta />
				</div>
			</>
		);
	}

	return (
		<>
			<div className={Styles.pricingDetails}>
				<Editorial bold>Starting prices</Editorial>
				<div className={Styles.faqs}>
					<Faq
						text="Reception"
						iconName="category-rec"
						value={pricingDetails.reception}
					/>
					<Faq
						text="Ceremony"
						iconName="category-cer"
						value={pricingDetails.ceremony}
					/>
					<Faq
						text="Bar services"
						iconName="category-bar"
						value={pricingDetails.barService}
					/>
					<Faq
						text="Catering"
						iconName="category-cat"
						value="Contact for price"
					/>
				</div>
				<DisclaimerText />
				<Cta />
			</div>
		</>
	);
};
