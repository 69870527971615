import { PricingCta } from '@components/detailed-pricing/PricingCta';
import { CATEGORY_SINGULAR_ABBREVIATED_TERMS } from '@constants/categories';
import { useAppSelector } from '@redux/hooks';
import { Body1 } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai';
import React, { useMemo } from 'react';
import type { CategoryCode } from 'types/Category';
import { packagesAtom } from '../../../jotai/storefront/detailed-pricing';
import { hasPricingDetailsAtom } from '../../../jotai/storefront/pricing';
import Styles from './CUSSecondaryCta.scss';

interface CUSSecondaryCtaProps {
	couplesUsuallySpend: string | null;
	isPackagePricing?: boolean;
}

export const CUSSecondaryCta = ({
	couplesUsuallySpend,
	isPackagePricing = false,
}: CUSSecondaryCtaProps) => {
	const hasPricingDetails = useAtomValue(hasPricingDetailsAtom);
	const packages = useAtomValue(packagesAtom);
	const categoryCode = useAppSelector(
		(state) => state.vendor.vendor?.categoryCode as CategoryCode,
	);
	const singularTerm = CATEGORY_SINGULAR_ABBREVIATED_TERMS[categoryCode];
	const hasPricingDetailsOrPackages = hasPricingDetails || packages.length;

	const body = hasPricingDetailsOrPackages
		? 'Reach out and share your wedding details.'
		: `Reach out to get a customized quote from this ${singularTerm}.`;

	const header = useMemo(() => {
		if (couplesUsuallySpend && !hasPricingDetailsOrPackages) {
			return 'Looking for more pricing details?';
		}

		if (hasPricingDetailsOrPackages) {
			return 'Are you interested?';
		}

		return 'No pricing details yet';
	}, [couplesUsuallySpend, hasPricingDetailsOrPackages]);

	return (
		<div className={Styles.noPricingDetailsWrapper}>
			<div className={Styles.noPricingDetails}>
				<Body1 bold>{header}</Body1>
				<Body1>{body}</Body1>
			</div>
			<PricingCta
				sourceContent="Packages"
				isPackagePricing={isPackagePricing}
			/>
		</div>
	);
};
