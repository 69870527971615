import { EstimatedPricingDetailsModal } from '@components/shared-pricing/EstimatedPricingDetailsModal';
import { DisplayButton } from '@xo-union/tk-ui-links';
import { Body2, Subhead } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai/index';
import React, { useState } from 'react';
import { estimateUsuallySpendAtom } from '../../jotai/storefront/detailed-pricing';
import { venueEstimateUsuallySpendAtom } from '../../jotai/storefront/pricing';
import Styles from './EstimatedUsuallySpend.scss';

const VENUE_PRICING_ELEMENTS = [
	'Site fees',
	'Guest count',
	'Catering',
	'Seasonality',
	'Bar services',
	'Taxes',
	'Gratuity',
];
const NON_VENUE_PRICING_ELEMENTS = [
	'Additional hours',
	'Travel',
	'Delivery',
	'Seasonality',
	'Set-up',
	'Multiple locations',
	'Extra personnel',
];

export const EstimatedUsuallySpend = ({ isVenue = false }) => {
	const estimatedPriceValue = useAtomValue(estimateUsuallySpendAtom);
	const venueEstimatedPriceValue = useAtomValue(venueEstimateUsuallySpendAtom);
	const storefrontDescriptor = isVenue ? "venue's" : "vendor's";
	const pricingElements = isVenue
		? VENUE_PRICING_ELEMENTS
		: NON_VENUE_PRICING_ELEMENTS;
	const [isModalOpen, setIsModalOpen] = useState(false);

	if (!estimatedPriceValue && !venueEstimatedPriceValue) return null;

	return (
		<div className={Styles.estimatedPricingWrapper}>
			<div className={Styles.estimatedPricingHeader}>
				<Subhead className={Styles.packageStartingCost} as="span" bold>
					{isVenue ? venueEstimatedPriceValue : estimatedPriceValue}
				</Subhead>
				<Body2 as="span">The Knot Estimate</Body2>
			</div>
			<div className={Styles.estimatedPricingBody}>
				The following elements can contribute to a {storefrontDescriptor} total
				cost:
				<ul className={Styles.estimatedPricingList}>
					{pricingElements.map((element) => (
						<li key={element}>{element}</li>
					))}
				</ul>
				<Body2 className={Styles.subtleText}>
					The Knot Estimate is based on real wedding data from couples and may
					include vendor contributions and market data insights. It is not an
					exact quote.{' '}
					<DisplayButton
						type="button"
						color="primary"
						className={Styles.moreDetailsButton}
						onClick={() => setIsModalOpen(true)}
					>
						More details
					</DisplayButton>
					<EstimatedPricingDetailsModal
						isModalOpen={isModalOpen}
						closeCallback={() => setIsModalOpen(false)}
					/>
				</Body2>
			</div>
		</div>
	);
};
